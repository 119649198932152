import { useRef } from 'react'
// @ts-ignore
import { Video, CloudinaryContext } from 'cloudinary-react'

type VideoPlayerProps = {
  publicId: string
}

export default function VideoPlayer({ publicId }: VideoPlayerProps) {
  const videoRef = useRef()

  return (
    <CloudinaryContext secure={true} cloud_name="meta-export">
      <div>
        <Video
          publicId={publicId}
          width="100%"
          height="100%"
          controls
          innerRef={videoRef}
          autoPlay
          loop
        />
      </div>
    </CloudinaryContext>
  )
}
