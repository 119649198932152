import { Button, Grid, Stack, Typography } from '@mui/material'
import Image from 'next/image'

export default function Hero() {
  return (
    <Grid mt="10%" mb={{ xs: '30%', lg: '15%' }} container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography variant="h2" component="h2" fontWeight={500} mb={4}>
          {'Save time.\nStay organized.'}
        </Typography>
        <Typography variant="h6" mb={4}>
          Convert your EBSCOHost search results into an easy-to-use spreadsheet
          for your systematic reviews and meta-analyses.
        </Typography>
        <Stack direction="row">
          <Button
            size="large"
            variant="contained"
            href="#convert-section"
            sx={{ px: 4, mr: 2 }}
          >
            Get Started
          </Button>
          <Button
            size="large"
            variant="outlined"
            href="#how-to-section"
            sx={{ px: 4 }}
          >
            Learn How
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={false} md={6}>
        <Image
          src="/images/hero_spreadsheet.svg"
          alt="Person standing next to spreadsheet"
          width={841}
          height={512}
          layout="responsive"
        />
      </Grid>
    </Grid>
  )
}
