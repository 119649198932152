import { Container, Stack } from '@mui/material'

type MainContentProps = {
  children?: JSX.Element
}

export default function Section({ children }: MainContentProps) {
  return (
    <Container sx={{ my: 20 }} component="section" maxWidth="lg">
      <Stack>{children}</Stack>
    </Container>
  )
}
