import { Article } from './articleParser'

export interface ValidatedArticle {
    article: Article
    missingProps: (keyof Article)[]
}

export function validateArticle(article: Article): ValidatedArticle {
    const missingProps = identifyMissingArticleInfo(article)
    return { article, missingProps }
}

function identifyMissingArticleInfo(article: Article) {
    const missingProps: (keyof Article)[] = []

    let key: keyof Article

    for (key in article)
        if (!article[key] || article[key] === 'undefined')
            missingProps.push(key)

    return missingProps
}
