import React from 'react'
import {
  AppBar as MuiAppBar,
  Container,
  Toolbar,
  Typography,
  Stack,
  Link,
} from '@mui/material'
import WebhookIcon from '@mui/icons-material/Webhook'

function AppBar() {
  return (
    <MuiAppBar>
      <Toolbar>
        <Container>
          <Stack direction="row" spacing={0.5} alignItems="center" py={1}>
            <WebhookIcon color="primary" sx={{ fontSize: '4rem' }} />
            <Link href="#" underline="none" color="textPrimary">
              <Typography component="h1" variant="h4" fontWeight={500}>
                Meta Export
              </Typography>
            </Link>
          </Stack>
        </Container>
      </Toolbar>
    </MuiAppBar>
  )
}

export default AppBar
