import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Stack } from '@mui/material'

interface Props {
  uploadFile: (file: File | undefined) => void
}

function FileDropzone({ uploadFile }: Props) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      uploadFile(file)
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <Stack
      sx={{
        minHeight: '15rem',
        height: '100%',
        width: '100%',
        border: '3px dotted grey',
        mt: 6,
        mb: 2,
        p: 4,
        aspectRatio: '16/9',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <p>Drag & drop your .xml file here, or click to select file</p>
    </Stack>
  )
}

export default FileDropzone
