import React from 'react'
import { Avatar, Typography, Grid, Stack } from '@mui/material'
import { green } from '@mui/material/colors'
import VideoPlayer from './VideoPlayer'

type HowToCardProps = {
  stepNumber: number
  headerText: string
  bodyText: string
  videoOnLeft?: boolean
  videoSrc: any
}

export default function HowToCard({
  stepNumber,
  headerText,
  bodyText,
  videoOnLeft = false,
  videoSrc,
}: HowToCardProps) {
  return (
    <Grid
      container
      direction={videoOnLeft ? 'row-reverse' : 'row'}
      spacing={12}
      sx={{ mb: 18 }}
    >
      <Grid container item md={6} xs={12} justifyContent="center">
        <VideoPlayer publicId={videoSrc} />
      </Grid>
      <Grid container item md={6} xs={12} justifyContent="center">
        <Stack maxWidth={500}>
          <Avatar
            alt={`Step number ${stepNumber}`}
            sx={{ color: '#fff', backgroundColor: green[700], mb: 1 }}
          >
            {stepNumber}
          </Avatar>
          <Typography component="h3" variant="h5" gutterBottom>
            {headerText}
          </Typography>
          <Typography gutterBottom>{bodyText}</Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}
