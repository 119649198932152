import { Element } from 'xml-js'
import * as xmlParser from './xmlParser'

export interface Article {
    resultId: number
    title: string
    abstract: string
    journalTitle: string
    year: number
    firstAuthor: string
    authors: string
    database: string
    doi: string
    pages: string
    pubtype: string
    language: string
    url: string
}

interface Authors {
    firstAuthor: string
    allAuthors: string
}

export function parseArticle(articleRootXmlELement: Element): Article {
    const articleElements = articleRootXmlELement.elements ?? []
    const resultId = Number(articleRootXmlELement?.attributes?.['resultID'])

    const pages = xmlParser.findValue('pages', articleElements)
    const pubtype = xmlParser.findValue('pubtype', articleElements)
    const language = xmlParser.findValue('language', articleElements)
    const url = xmlParser.findValue('url', articleElements)
    const title = xmlParser.findValue('atl', articleElements)
    const journalTitle = xmlParser.findValue('jtl', articleElements)

    const abstract = findAbstract(articleElements)
    const year = findYear(articleElements)
    const doi = findDoi(articleElements)
    const database = findDatabase(articleElements)

    const authors = findAuthors(articleElements)
    const { firstAuthor, allAuthors } = authors

    return {
        resultId,
        title,
        journalTitle,
        year,
        abstract,
        firstAuthor,
        authors: allAuthors,
        database,
        doi,
        pages,
        pubtype,
        language,
        url,
    }
}

function findDoi(elements: Element[]) {
    const uiElements = xmlParser.findElements('ui', elements)
    const doiElement = xmlParser.findElementWithAttributeValue(
        'type',
        'doi',
        uiElements
    )
    const doi = xmlParser.extractText(doiElement)
    return doi
}

function findDatabase(elements: Element[]) {
    const headerElements = xmlParser.findElements('header', elements)
    const database = String(
        xmlParser.findAttributeValue('longDbName', headerElements)
    )
    return database
}
function findAbstract(elements: Element[]) {
    const abstractElements = xmlParser.findElements('ab', elements)
    const abstractLines = abstractElements?.map((el) =>
        xmlParser.extractText(el)
    )

    const combinedAbstractLines = abstractLines.join(' ')
    return combinedAbstractLines
}

function findAuthors(elements: Element[]): Authors {
    const augElements = xmlParser.findElements('aug', elements)
    const firstNonEmptyAugElement = augElements.find((aug) => aug.elements)

    const authorElements = firstNonEmptyAugElement?.elements
    if (!authorElements) return { firstAuthor: '', allAuthors: '' }

    const allAuthors: string[] = authorElements.map((e) =>
        xmlParser.extractText(e)
    )
    const firstAuthor = allAuthors[0]

    const concatenatedAuthors = allAuthors.join('; ')

    return { firstAuthor, allAuthors: concatenatedAuthors }
}

function findYear(elements: Element[]): number {
    const pubDateElement = xmlParser.findElements('dt', elements)[0]
    const year = Number(pubDateElement?.attributes?.year)

    return year
}
