import { Element } from 'xml-js'

export function findValue(elementName: string, elements: Element[]) {
    const matchingElements = findElements(elementName, elements)
    const value = extractText(matchingElements[0])
    return value
}

export function findAttributeValue(attribute: string, elements: Element[]) {
    const element = elements.find((e) => e.attributes?.[attribute])
    return element?.attributes?.[attribute]
}

export function findElementWithAttributeValue(
    attribute: string,
    value: string,
    elements: Element[]
) {
    const element = elements.find((e) => e.attributes?.[attribute] === value)
    return element
}

export function findElements(name: string, elements: Element[]): Element[] {
    if (!elements || !elements.length) return []

    const foundElements = elements.filter((e) => e.name === name)

    if (foundElements.length >= 1) return foundElements

    const subElements = elements
        .filter((e) => e.elements !== undefined)
        .flatMap((e) => e.elements) as Element[]

    return findElements(name, subElements)
}

export function extractText(record: Element | undefined): string {
    const textElement = record?.elements

    const text = String(textElement?.[0]?.text) ?? ''
    return text
}
