import React from 'react'
import { SnackbarProvider } from 'notistack'

import AppBar from './components/AppBar'
import Footer from './components/Footer'
import Section from './components/Section'
import HowTo from './components/HowTo'
import Hero from './components/Hero'
import Convert from './components/Convert'

export default function App() {
  return (
    <SnackbarProvider>
      <AppBar />
      <main>
        <Section>
          <Hero />
        </Section>
        <Section>
          <Convert />
        </Section>
        <Section>
          <HowTo />
        </Section>
      </main>
      <Footer />
    </SnackbarProvider>
  )
}
