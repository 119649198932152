import converter, { Element } from 'xml-js'
import { validateArticle } from './articleValidator'
import { Article, parseArticle } from './articleParser'
import { replaceMissingValues } from './missingValueReplacer'

export function processXml(xml: string) {
  const articles = parseArticlesFromXml(xml)
  const validatedArticles = articles.map(validateArticle)
  const validatedArticlesWithMissingValuesReplaced =
    validatedArticles.map(replaceMissingValues)

  return validatedArticlesWithMissingValuesReplaced
}

function sanitizeArticle(article: Article) {
  let key: keyof Article

  for (key in article)
    if (typeof article[key] === 'string') {
      const propValue = article[key] as string
      // @ts-ignore
      article[key] = propValue.replace(/"/g, '""')
    }

  return article
}

function extractArticleElementsFromXml(xml: string): Element[] {
  const xmlDocument = converter.xml2js(xml)
  const articleElements = xmlDocument.elements[0].elements
  return articleElements
}

export function parseArticlesFromXml(xml: string): Article[] {
  const articleElements = extractArticleElementsFromXml(xml)
  const articles = articleElements.map(parseArticle)
  const sanitizedArticles = articles.map(sanitizeArticle)
  return sanitizedArticles
}
