import React, { useState, useEffect } from 'react'
import { Button, CircularProgress, Grid, Stack } from '@mui/material'
import { CSVLink } from 'react-csv'
import { Save } from '@mui/icons-material'
import { pick } from 'lodash'
import { useSnackbar } from 'notistack'

import FileDropzone from './FileDropzone'
import { Article } from '../parser/articleParser'
import ColumnExportOptions, { ColumnOptions } from './ColumnExportOptions'
import { processXml } from '../parser/xmlFileProcesser'
import csvColumnHeaders from './CSVHeaders'

export default function CSVConverter() {
  const [file, setFile] = useState<File>()
  const [csvData, setCsvData] = useState<Article[]>([])
  const [loading, setLoading] = useState(false)

  const [exportColumns, setExportColumns] = useState<ColumnOptions>({
    resultId: true,
    title: true,
    abstract: true,
    journalTitle: true,
    year: true,
    firstAuthor: true,
    authors: true,
    database: true,
    doi: true,
    pages: true,
    pubtype: true,
    language: true,
    url: true,
  })

  const { enqueueSnackbar } = useSnackbar()

  function handleFileError(msg?: string) {
    enqueueSnackbar(msg || 'Failed to process xml file.', {
      variant: 'error',
    })

    setLoading(false)
    setFile(undefined)
  }

  useEffect(() => {
    if (!file) return

    const reader = new FileReader()
    reader.onerror = () => handleFileError()
    reader.onloadstart = () => setLoading(true)
    reader.onload = () => {
      try {
        const articles = processXml(reader.result as string).map(
          (v) => v.article
        )
        setCsvData(mapArticlePropsToSpreadsheetColumns(articles))
        setLoading(false)
      } catch (error) {
        handleFileError()
      }
    }
    reader.readAsText(file)
  }, [file])

  useEffect(() => {
    if (file && !loading)
      setCsvData(mapArticlePropsToSpreadsheetColumns(csvData))
  }, [exportColumns])

  const handleColumnOptionChange = (
    option: keyof ColumnOptions,
    checked: boolean
  ) => {
    setExportColumns({ ...exportColumns, [option]: checked })
  }

  const mapArticlePropsToSpreadsheetColumns = (articles: Article[]) => {
    const exportColumnKeys = Object.keys(exportColumns) as Array<
      keyof ColumnOptions
    >
    const selectedColumns = exportColumnKeys.filter(
      (key) => exportColumns[key] === true
    )

    const spreadsheetData = articles.map((a) =>
      mapArticleToRow(a, selectedColumns)
    )

    return spreadsheetData
  }

  const mapArticleToRow = (
    article: Article,
    selectedColumns: (keyof Article)[]
  ) => {
    const row = pick(article, selectedColumns)
    return row
  }

  const uploadFile = (file: File | undefined) => {
    if (!file) return handleFileError('Error uploading file!')

    if (file?.type !== 'text/xml')
      return handleFileError('You must upload an xml file.')

    setFile(file)
  }

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} md={9}>
        <Stack width="100%" justifyContent="center" alignItems="center">
          <FileDropzone uploadFile={uploadFile} />
          <CSVLink
            onClick={() => {
              if (!file) return false
            }}
            data={csvData}
            headers={csvColumnHeaders}
            filename={`${file?.name.split('.')[0]}.csv`}
            target="_blank"
          >
            <Button
              variant="contained"
              disabled={loading || !file}
              color="primary"
              size="large"
              startIcon={
                loading ? <CircularProgress size="1.5rem" /> : <Save />
              }
            >
              Download Spreadsheet
            </Button>
          </CSVLink>
        </Stack>
      </Grid>
      <Grid container item xs={12} md={3} justifyContent="center">
        <ColumnExportOptions
          options={exportColumns}
          handleChange={handleColumnOptionChange}
        />
      </Grid>
    </Grid>
  )
}
