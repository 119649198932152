import { ValidatedArticle } from './articleValidator'

export function replaceMissingValues(validatedArticle: ValidatedArticle) {
  const article = validatedArticle.article

  article.title = replaceMissingValue(article.title)
  article.abstract = replaceMissingValue(article.abstract)
  article.journalTitle = replaceMissingValue(article.journalTitle)
  article.firstAuthor = replaceMissingValue(article.firstAuthor)
  article.authors = replaceMissingValue(article.authors)
  article.database = replaceMissingValue(article.database)
  article.doi = replaceMissingValue(article.doi)
  article.pages = replaceMissingValue(article.pages)
  article.pubtype = replaceMissingValue(article.pubtype)
  article.language = replaceMissingValue(article.language)
  article.url = replaceMissingValue(article.url)

  return validatedArticle
}

function replaceMissingValue(value: string | undefined) {
  if (!value || value === 'undefined') return ''

  return value
}
