import { Button, Stack, Typography } from '@mui/material'
import CSVConverter from './CSVConverter'

export default function Convert() {
  return (
    <Stack alignItems="center">
      <Typography
        component="h2"
        variant="h3"
        fontWeight={400}
        align="center"
        id="convert-section"
        sx={{ scrollMarginTop: 120 }}
      >
        Convert Your Search Results Here
      </Typography>
      <Button variant="text" href="#how-to-section">
        Go to Instructions
      </Button>
      <CSVConverter />
    </Stack>
  )
}
