import { Article } from './../parser/articleParser'

type csvColumnHeader = {
  label: string
  key: keyof Article
}

const csvColumnHeaders: csvColumnHeader[] = [
  { label: 'Result ID', key: 'resultId' },
  { label: 'Title', key: 'title' },
  { label: 'Abstract', key: 'abstract' },
  { label: 'Journal Title', key: 'journalTitle' },
  { label: 'Year', key: 'year' },
  { label: 'First Author', key: 'firstAuthor' },
  { label: 'Authors', key: 'authors' },
  { label: 'Database', key: 'database' },
  { label: 'DOI', key: 'doi' },
  { label: 'Pages', key: 'pages' },
  { label: 'Pubtype', key: 'pubtype' },
  { label: 'Language', key: 'language' },
  { label: 'URL', key: 'url' },
]

export default csvColumnHeaders
