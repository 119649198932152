import { Typography } from '@mui/material'
import React from 'react'

import HowToCard from './HowToCard'

const howToData = [
  {
    headerText: 'Perform your search',
    bodyText:
      "Login into EBSCOHost using your institution's login. Select the desired databases to search through. Refine your search until you have the search results that you want to export to a spreadsheet.",
    videoOnLeft: false,
    stepNumber: 1,
    videoSrc: 'meta-export/MetaExport_Step1',
  },
  {
    stepNumber: 2,
    headerText: 'Export your results',
    bodyText:
      'After performing your search, click on the "Share" button in top-right corner, and click on the the link under "Export Results". This will open the "Export Manager" page. On the right side, select "Citations in XML format". On the left side of the page, type your email in the "E-mail to:" text box and click "Send".',
    videoOnLeft: true,
    videoSrc: 'meta-export/MetaExport_Step2',
  },
  {
    stepNumber: 3,
    headerText: 'Upload your file for conversion',
    bodyText:
      'You\'ll receive an email with the exported results. Click on the link in the email to download a .zip archive of your search results. Find the downloaded .zip on your computer and extract. Drag & drop the extracted .xml file to convert the file to a spreadsheet. Select the columns you want and click "Download Spreadsheet".',
    videoOnLeft: false,
    videoSrc: 'meta-export/MetaExport_Step3',
  },
]

export default function HowTo() {
  return (
    <>
      <Typography
        component="h2"
        variant="h3"
        fontWeight={400}
        align="center"
        id="how-to-section"
        sx={{ scrollMarginTop: 120 }}
      >
        How to Generate Your Spreadsheet
      </Typography>
      <Typography
        sx={{ mb: 8 }}
        variant="subtitle1"
        fontWeight={300}
        align="center"
      >
        Get your EbscoHost search results in an easy-to-use spreadsheet!
      </Typography>
      {howToData.map((data) => (
        <HowToCard
          key={data.stepNumber}
          stepNumber={data.stepNumber}
          headerText={data.headerText}
          bodyText={data.bodyText}
          videoOnLeft={data.videoOnLeft}
          videoSrc={data.videoSrc}
        />
      ))}
    </>
  )
}
