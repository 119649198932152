import React from 'react'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
} from '@mui/material'
import { startCase } from 'lodash'

import { Article } from '../parser/articleParser'

export type ColumnOptions = {
  [Property in keyof Article]: boolean
}

type ColumnExportOptionProps = {
  options: ColumnOptions
  handleChange: (option: keyof ColumnOptions, checked: boolean) => void
}

export default function ColumnExportOptions({
  options,
  handleChange,
}: ColumnExportOptionProps) {
  const optionsKeys = Object.keys(options) as Array<keyof ColumnOptions>

  return (
    <Paper sx={{ p: 2, mt: 6, maxWidth: '16rem' }}>
      <FormControl component="fieldset" variant="standard" focused={false}>
        <FormLabel component="legend">Choose spreadsheet columns</FormLabel>
        <FormGroup>
          {optionsKeys.map((optionKey) => (
            <FormControlLabel
              key={optionKey}
              label={startCase(optionKey)}
              control={
                <Checkbox
                  color="primary"
                  checked={options[optionKey]}
                  onChange={(event) =>
                    handleChange(optionKey, event.target.checked)
                  }
                />
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    </Paper>
  )
}
