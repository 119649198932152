import { Box, Stack, Link, Typography, Container } from '@mui/material'
import React from 'react'

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        borderTop: 1,
        borderColor: 'rgba(255, 255, 255, 0.08)',
      }}
    >
      <Container>
        <Stack direction="row" justifyContent="space-between" sx={{ py: 2 }}>
          <Typography>&copy; 2022 Garrett Smith</Typography>
          <Typography>
            <Link
              underline="hover"
              color="textPrimary"
              href="mailto:garrettsmithdev@gmail.com"
            >
              Contact
            </Link>
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}
